import { API, graphqlOperation, Storage } from "aws-amplify";
import { GraphQLError } from "@connectpath/common-frontend";
import { isValidJSON, ErrorCodes } from "@connectpath/common";

export function fetcherAmplify() {
  const graphql = async ({ query, variables }) => {
    try {
      const response = await API.graphql(graphqlOperation(query, variables));
      return response.data;
    } catch (error) {
      const { message } = error.errors[0];
      if (isValidJSON(message)) {
        const { errorCode, errorMessage, errorParams } = JSON.parse(message);
        throw new GraphQLError(errorCode, errorMessage, errorParams);
      }
      console.error(`fetcherAmplify > graphql > error > `, error);
      throw new GraphQLError(ErrorCodes.ERR0000, "Something went wrong", null);
    }
  };

  const storage = () => {
    const uploadToS3 = async ({ fileName, file, config }) => {
      try {
        const { key } = await Storage.put(fileName, file, config);
        return key;
      } catch (error) {
        console.error(`fetcherAmplify > storage > uploadFileToS3 > error > `, error);
        throw Error("Something went wrong");
      }
    };

    const getFileS3 = async ({ key, config }) => {
      try {
        return await Storage.get(key, config);
      } catch (error) {
        console.error(`fetcherAmplify > storage > getFileS3 > error > `, error);
        throw Error("Something went wrong");
      }
    };

    return {
      uploadToS3,
      getFileS3,
    };
  };

  return {
    graphql,
    storage,
  };
}
